/*@font-face {*/
/*    font-family:"Gotham HTF Book";*/
/*    src: local('Gotham HTF'), url("../fonts/GothamHTF-Book.ttf") format("truetype");*/
/*}*/
/*@font-face {*/
/*    font-family:"Gotham HTF Bold";*/
/*    src: local('Gotham HTF Bold'), url("../fonts/GothamHTF-Bold.ttf") format("truetype");*/
/*    font-weight: bold;*/
/*}*/

.clinical-main{
    text-align: left;
    position: absolute;
    margin-top: 10%;
    margin-left: 40%;
}
.lab-results-search {
    padding-left: 25%;
    padding-right: 25%;
}
.lab-results-search.textinput{

}
.title-small{
    margin-top: 3%;
    margin-bottom: 2%;
    font-family: GothamHTF-Bold;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height */
    text-align: center;
}
.search-input {
    margin-top: 5%;
    margin-bottom: 5%;
    border: 1px solid #7C7C7E;
    box-sizing: border-box;
    /* width: 438px; */
    height: 56px;
    border-radius: 2px;
}
.add-lab-result{
    font-family: Gotham HTF Book;
    font-size: 14px;
    line-height: 20px;/* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #40B3AB;
    font-weight: bold;
}

.nav-tabs {
    /* border-bottom: 1px solid #dee2e6; */
    font-family: Gotham HTF Book;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #3B3A3E;
    border-bottom: 1px solid transparent;
    margin-top:3%;
    margin-bottom: 7%;
    border-bottom: 1px solid #E9E9E9;
}
.nav-tabs .nav-link {
    color: #495057;
    background-color: #fff;
    border:0px;
    border-bottom: 1px solid transparent;
    font-weight: normal;
    width: 50%;
}
/* .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border:0px;
    border-bottom: 1px solid #40B3AB;
    font-weight: bold;

} */
ul{
    columns: 2;
    -webkit-columns: 2;
    list-style: none;
    -moz-columns: 2;
    padding-top: 3%;
    padding-bottom: 3%;
    text-align: -webkit-auto;
    padding-left: 0%;
    padding-right: 0%;
    font-family: Gotham HTF Book;
    font-size: 13px;
    line-height: 19px;
    color: #000000;

}
.lab-result-container{
    border-bottom: 1px solid #E9E9E9;
}
li{
    margin-bottom: 8px;
}


.lab-results-cb{

    display: -webkit-box;;
}
/*//////////////*/
.lab-result-cb-left{
    padding-left: 25%;
    padding-right: 25%;

    cursor: pointer;
    font-family: Gotham HTF Book;
    font-size: 12px;
    line-height: 14px;/* identical to box height */
    display: flex;
    align-items: center;
    color: #3B3A3E;

    background: transparent;
    border: 1px solid #F3F3F3;
    border-radius: 32px 0 0 32px;
}

.lab-result-cb-central{
    padding-left: 25%;
    padding-right: 25%;

    cursor: pointer;
    font-family: Gotham HTF Book;
    font-size: 12px;
    line-height: 14px;/* identical to box height */
    display: flex;
    align-items: center;
    color: #3B3A3E;

    background: transparent;
    border: 1px solid #F3F3F3;
}

.lab-result-cb-right{
    padding-left: 12%;
    padding-right: 0%;

    cursor: pointer;
    font-family: Gotham HTF Book;
    font-size: 12px;
    line-height: 14px;/* identical to box height */
    display: flex;
    align-items: center;
    color: #3B3A3E;

    background: transparent;
    border: 2px solid #F3F3F3;
    border-radius: 0 32px 32px 0;
}
.lab-result-cb-right.checked{
    font-weight: bold;
    color:  #605A57;
    background:  #F3F3F3;

    border: 1px solid #F3F3F3;
}
.lab-result-cb-central.checked{
    font-weight: bold;
    color: #50869F;
    background: rgba(80, 134, 159, 0.1);

    border: 1px solid rgba(80, 134, 159, 0.1);
}
.lab-result-cb-left.checked{
    font-weight: bold;
    color: #FF5B45;
    background: rgba(255, 91, 69, 0.1);

    border: 1px solid rgba(255, 91, 69, 0.1);
}

.lab-results-cb-custom-radio-container{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    height: 39px;
    margin: 5%;
}



.lab-result-cb-normal{
    display: grid;
    grid-template-columns: 100%;
    height: 39px;
    margin: 5%;
    text-align: center;
    border: 1px solid #E9E9E9;
    border-radius: 32px;
    padding-top: 10px;
}
.lab-result-cb-normal.checked{
    font-weight: bold;
    color: #50869F;
    background: rgba(80, 134, 159, 0.1);

    border: 1px solid rgba(80, 134, 159, 0.1);
}
/*//////////////////////////*/

.search-result-container{
    text-align: right;
    font-family: Gotham HTF Book;
    font-size: 22px;
    line-height: 35px;
    color: #3B3A3E;

    display: grid;
    grid-template-columns: 50% 50%;
}
.search-result-label{
    text-align: left;
}
.custom-button label{

    cursor:pointer;
    color: #40B3AB;
    text-align: right;
}
.labresult-grid{
    margin-top: 5%;
    display: grid;
    grid-template-columns: 50% 50%;
}
.labresult-grid-item{
    text-align: -webkit-left
}
.labresult-grid-placeholder{
    width: 100%;
    height: 24px;
}

.my-icon-c{
    display: -webkit-inline-flex;
    /* display: inline-flex; */
    border: 0px;
    /* border-bottom: 2px solid #605A57; */
    /* border-radius: 0px; */
    /* opacity: 0.5; */
    margin-left: -15%;
    margin-right: 4%;
    margin-bottom: 0px;
}

.clinical-label{
    font-family: Gotham HTF Book;
    font-size: 14px;
    line-height: 15px;
    /* identical to box height */

    /* midlle */
    color: #B4B4B4;
}
.clinical-item{
    font-family: Gotham HTF Book;
    font-size: 16px;
    line-height: 17px;
    /* identical to box height */

    color: #3B3A3E;

}

.clinic-info-grid{
    display: grid;
    grid-template-columns: 13% 26% 21% 20% 20% ;
    margin-top: 3%;
}
.clinic-info-grid-item{
    text-align: left;
    font-family: Gotham HTF Book;
    font-size: 16px;
    line-height: 23px;
}

.edit-button{
    cursor: pointer;;
    /* B2 small buttons 14 */
    font-family: Gotham HTF Book;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */
    text-transform: uppercase;
    font-weight: bold;
    color: #40B3AB;
    text-align: right !important;
}

.users-table-header{
    display: grid;
    grid-template-columns: 33% 34% 33%;
    margin-top: 5%;
    margin-bottom: 2%;
    font-family: GothamHTF-Bold;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height */
    text-align: center;
}
.users-table{
    text-align: initial;
    font-family: Gotham HTF Book;
    font-size: 16px;
    /* line-height: 17px; */
}

.table-bordered td, .table-bordered th {
    border: 1px solid transparent;
    padding-left: 0px;
    padding-right: 0px;
}
.outer-container{
    padding-left: 43%;
    padding-right: 0%;
    margin-top: 3px;

}
.role-container{
    text-align: center;
    /* font-weight: bold; */
}
.role-container.disabled{
    color: #7C7C7E;
}
.clinic-location-container{
    text-align: initial;
}


.form-group{
    border:0px solid;
    margin:0;
}

.form-control{

    /* position: absolute; */
    /* min-width: 251px;
    max-width: 331px; */
    /* height: 2px; */
    /* left: 849px; */
    /* top: 414px; */
    font-family: Gotham HTF Book;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: normal;
    /* color: #35336D; */
    opacity: 0.5;
    border: 0px;
    /* border-bottom: 2px solid #605A57; */
    border-radius: 0px ;
}
.form-group.not-valid{
    border: 1px solid rgba(240, 0, 0, 0.507);
    border-radius: 8px 8px;
    margin:0;
}
.input-with-icon-cd{
    text-align: center;
    display: inline-flex;
    min-width: 100%;
}

.input-with-icon-cd.special{
    display: grid;
    grid-template-columns: 90% 10%;
    max-width: 260px;
    margin: auto;
    text-align: left;
    font-family: Gotham HTF Book;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: normal;
    opacity: 0.5;
}

#my-form-check-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0 !important;
}
.clinical-form{
    padding-right: 2vw;
    padding-left: 2vw;
}
