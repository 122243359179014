
.main-container{
  display: grid;
  grid-template-columns: auto auto;
  text-align: -webkit-center;
}
.bglt{
  position: absolute;
  left: 0;
  top: 25%;
  max-height: 58%;
  width: 12vw;
  height: 50vh;
}
.bgrt{
  position: absolute;
  right: 0;
  top: 0;
  max-width: 50%;
  max-height: 40%;
  width: auto;
  height: auto;
}
.bgrb{
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 50%;
  max-height: 40%;
  width: auto;
  height: auto;
}
.Login,.Signup ,.ForgotPass{
  width: -webkit-fill-available;
  height: max-content;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin:auto;
}
.Login form {
  width: fit-content;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.52);
  text-align: -webkit-center;
  padding-left: 70px;
  padding-left: 3.6vw;
  padding-right: 70px;
  padding-right: 3.6vw;
}
.Signup form {
  width: 25vw;
  /* height: 59vh; */
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.52);
  text-align: -webkit-center;
  /* display: grid; */
  padding-left: 70px;
  padding-left: 3.6vw;
  padding-right: 70px;
  padding-right: 3.6vw;
  zoom:80%;
}
.ForgotPass form {
  width: 25vw;
  /* height: 59vh; */
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.52);
  text-align: -webkit-center;
  /* display: grid; */
  padding-left: 70px;
  padding-left: 3.6vw;
  padding-right: 70px;
  padding-right: 3.6vw;

}
.city-control{

  /* position: absolute; */
  min-width: 0px !important;
  /* max-width: 174px; */
  /* height: 2px; */
  /* left: 849px; */
  /* top: 414px; */
  font-family: Gotham HTF Book;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: normal;
  /* color: #35336D; */
  opacity: 0.5;
  border: 0px;
  border-bottom: 2px solid #605A57;
  border-radius: 0px;
}
.zip-control{
  /* position: absolute;  */
  min-width: 0px !important;
  /* max-width: 118px; */
  font-family: Gotham HTF Book;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: normal;
  color: #605a57 !important;
  opacity: 0.5;
  border: 0px;
  border-bottom: 2px solid #605A57;
  border-radius: 0px;
}
.Login .form-control ,.Signup .form-control{

  /* position: absolute; */
  /* min-width: 251px;
  max-width: 331px; */
  /* height: 2px; */
  /* left: 849px; */
  /* top: 414px; */
  font-family: Gotham HTF Book;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: normal;
  /* color: #35336D; */
  opacity: 0.5;
  border: 0px;
  /* border-bottom: 2px solid #605A57; */
  border-radius: 0px ;
}

.gynilogo{
  display: -webkit-flex;
  display: flex;
  margin-bottom: 11vh;
}

.link-button{
  font-family: GothamHTF-Bold;
  font-style: normal;
  font-weight: bold;
  margin-top: 3.5vh;
  font-size: 12px;
  /* line-height: normal; */
  color: #847f7ccf;
}
.link-button.large{
  font-family: Gotham HTF Book;
  font-size: 18px;
}
.link-button:hover{
  color: #3AB5AC;
}



label{
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.big-label{
  font-family: GothamHTF-Bold;
  font-size: 3.8vw;
  line-height: normal;
  color: #605A57;
  font-weight: bold;
  display: grid;
  text-align: initial;
}

.small-label{
  padding-top: 8vh;
  font-family: Gotham HTF Book;
  font-size: 24px;
  line-height: 35px;


  color: #3B3A3E;
  text-align: center;
}

.right-block{
  margin-right: auto;
  margin-left: auto;
  margin-top: 5%;
  padding-left: 5%;
}
.left-block{

}



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  border: 0px;
  /* border-bottom: 2px solid #605A57; */
  border-radius: 0px;
}

.city-container{
  display: -webkit-flex;
}

.country-select{
  min-width: 240px;
}

.select-container{
  border-bottom: 2px solid #605a5780 !important;
}
.css-yk16xz-control{
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0,0%,100%);
  border-bottom: 0px solid #605a5780 !important;
  /* border-radius: 0px !important; */
  border-width: 0px  !important;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}


.region-dropdown{
  font-family: Gotham HTF Book;
  font-style: normal;
  font-weight: normal;
  /* font-size: 16px; */
  /* line-height: normal; */
  color: #35336D;
  opacity: 0.5;
  border: 0px;
  /* border-bottom: 2px solid #605A57; */
  border-radius: 0px;
  width: 100%;
  height: 38px;
}



.signup-next-button{
  height: 6vh;
  width: 15vw;
  border: 0px;
  font-family: Gotham HTF Book;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 5.7vh;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #3AB5AC;
  border-radius: 16vh;
}
.custom-form{
  min-height: auto !important;
}

.indicators-grid{

  display: grid;
  grid-template-columns: 33% 34% 33%;
  display: inline-flex;
}
.indicator-led{

  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #E5E5E5;
  /* border: 1px #3AB5AC solid; */
}
.indicator-separator{
  width: 6px;
}
.indicator-led.green{
  background:#3AB5AC;
}
.form-group{
  margin:0;
  padding: auto;
}


/* .carousel-item{
  height: 356px !important;
} */
.sign-in-button-container{
  margin-top: 1.2vh;
  padding-bottom: 4.6vh;
}
#signinbutton{
  font-weight: bold;
  font-family: Gotham HTF Book;
  font-size: 18px;
  line-height: 19px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  color: #40B3AB;
  text-transform: uppercase;

  display: inline;
  cursor: pointer;
  margin-top:12px;
}
.my-icon{
  min-width: 30px;
}




#welcome-title{
  font-weight: bold !important;
}

.forgot-remember-grid{
  display: grid;
  grid-template-columns: 50% 50%;
  max-width: 387px;

  font-size: 0.6vw !important;
}
/* .remember-me-grid{
  font-size: 12px;
} */

.Loader-button-container{
  margin-top:70px;
}


#clinicName{
  padding: 4px;
}



.input-with-icon{
  margin-top: 3.8vh;
  text-align: center;
  display: -webkit-inline-flex;
  display: inline-flex;
  width: -webkit-fill-available;
  margin-left: 5px;
  border-bottom: 2px solid #605a5754;
}
.input-with-icon.wrong{

  border-bottom: 1px solid #e7041fe7;
}
.validation-error{
  font-family: Gotham HTF Book;
  font-size: 12px;
  line-height: 13px;
  /* identical to box height */

  color: #FF5B45;
  text-align: left;
  padding-left: 1vw;
}
.validation-error.shifted{
  padding-left: 12%
}
.validation-error.veryshifted{
  padding-left: 29%
}
.validation-error.moreshifted{
  padding-left: 26.5%;
}
.version-label{
  position: absolute;
  font-family: Gotham HTF Book;
  bottom: 1%;
  left: 1%;
}
#reset-button {
  margin-top: 10%;
}

.message-sent{
  font-size: 2.7vw;
}
