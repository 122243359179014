@font-face {
  font-family:"Gotham HTF Book";
  src: local('Gotham HTF'), url("./fonts/GothamHTF-Book.ttf") format("truetype");
}
@font-face {
  font-family:"Gotham HTF Bold";
  src: local('Gotham HTF Bold'), url("./fonts/GothamHTF-Bold.ttf") format("truetype");
  /*font-weight: bold;*/
}


/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px #f8fcfd inset !important;
  border: 2px solid rgba(19, 150, 180, 0.2) !important;
}
input.datepicker {
  text-transform: uppercase;
}
/*.App {*/
/*  text-align: center;*/
/*}*/





/*!* BOOTSTRAP OVVERRIDE *!*/
/*@media (min-width: 576px){*/
/*  .container {*/
/*      max-width: 948px;*/
/*  }*/
/*}*/

/*.App-logo {*/
/*  animation: App-logo-spin infinite 20s linear;*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*!*}*!*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

/*.dropdown-menu{*/
/*  transform: translate3d(10px, 18px, 0) !important;*/
/*}*/
/*!*!**!*/
/*.container{*/
/*  width: 100%;*/
/*    padding-right: 0px;*/
/*    padding-left: 0px;*/
/*    margin-right: 0;*/
/*    margin-left: 0;*/
/*} *!*/
