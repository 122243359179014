.clinic-control-panel{
    background: #d1d8d7;
    width: -webkit-fill-available;
    height: 30px;
    text-align: center;
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
    /* box-shadow: 0px 0px 10px 1px grey; */
    /* border-radius: 10px; */
}
.expanded-row-container{
    max-height: 500px;
    overflow: auto;
    background: #50869f00;
    border: 1px solid;
    border-radius: 0%;
    box-shadow: 0px 1px 21px 2px grey;
}
.my-custom-button{
    text-align: -webkit-center;
    /*padding-top: 13%;*/
    border: 1px black solid;
    border-radius: 4px;
    background: #d1d8d7;
    opacity: 0.8;
    font-size: 12px;
    min-width: -webkit-fill-available;
    min-height: -webkit-fill-available;
    box-shadow: 1px 1px 6px -2px black;
    cursor: pointer;
}
.my-custom-button:hover{
    opacity: 1;
    box-shadow: 0px 0px 0px 0px black;
}
.my-custom-form{
    display: grid;
    text-align: center;
    /* grid-template-columns: 33% 33% 33%; */
    /* padding: 5%; */
}
.graphic{
    zoom: 71%;
    padding: 36px;
}
.graphic.hidden{
    display: none;
}




.clinics-and-users-form-control{
    text-align: center;
}

.form-control-dropdown{
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: normal;
    /* color: #35336D; */
    opacity: 0.5;
    border: 0px;
    /* border-bottom: 2px solid #605A57; */
    border-radius: 0px;
    margin: auto;
}