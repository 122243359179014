html, body{
  height: 100%;
}

a {
  color: white;
}

.long {
  width: 100%;
  background-color: #010e19;
}

.container {
  height: 400px;
}

.scroll {
  opacity: 0.3;
  background-color: #3ab5ac;
  /* width: 40px; */
  /* height: 40px; */
  position: fixed;
  bottom: 100px;
  right: 100px;
  border-radius: 35%;
  border: none;
}
.scroll:hover{
  opacity: 1;
  background-color: #40908b;
}
.scroll.hidden{
  opacity: 0;
}
.arrow-up {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -5px;
}
.scroll-button-up{
  outline: none !important;
  font-family: Gotham HTF Book;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;

}
