.download-button{
    cursor: pointer;
}
.download-button:hover{
    border-bottom: 1px blue solid;
    color: blue;
}
.diag-container{
    text-align: left;
    max-height: 72px;
    overflow: auto;
}
.my-datepicker{
    width: 10%;
    height: 30px;
    text-align: -webkit-center;
    padding-top: -1%;
    border: 1px black solid;
    border-radius: 4px;
    background: #d1d8d7;
    opacity: 0.8;
    font-size: 12px;
    min-width: -webkit-fill-available;
    min-height: -webkit-fill-available;
    box-shadow: 1px 1px 6px -2px black;
    cursor: pointer;
}

