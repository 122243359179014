input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {

}

/*@media only screen and (max-height: 300px)                          {.login-zoom-by-height{zoom: 0.35;}}*/
/*@media only screen and (min-height: 300px) and (max-height: 350px)  {.login-zoom-by-height{zoom: 0.40;}}*/
/*@media only screen and (min-height: 350px) and (max-height: 400px)  {.login-zoom-by-height{zoom: 0.45;}}*/
/*@media only screen and (min-height: 400px) and (max-height: 450px)  {.login-zoom-by-height{zoom: 0.50;}}*/
/*@media only screen and (min-height: 450px) and (max-height: 500px)  {.login-zoom-by-height{zoom: 0.55;}}*/
/*@media only screen and (min-height: 500px) and (max-height: 550px)  {.login-zoom-by-height{zoom: 0.60;}}*/
/*@media only screen and (min-height: 550px) and (max-height: 600px)  {.login-zoom-by-height{zoom: 0.65;}}*/
@media only screen and (min-height: 600px) and (max-height: 650px)  {.login-zoom-by-height{zoom: 0.70;}}
@media only screen and (min-height: 650px) and (max-height: 700px)  {.login-zoom-by-height{zoom: 0.75;}}
@media only screen and (min-height: 700px) and (max-height: 750px)  {.login-zoom-by-height{zoom: 0.75;}}
@media only screen and (min-height: 750px) and (max-height: 800px)  {.login-zoom-by-height{zoom: 0.75;}}
@media only screen and (min-height: 800px) and (max-height: 850px)  {.login-zoom-by-height{zoom: 0.80;}}
@media only screen and (min-height: 850px) and (max-height: 900px)  {.login-zoom-by-height{zoom: 0.88;}}
@media only screen and (min-height: 900px) and (max-height: 950px)  {.login-zoom-by-height{zoom: 1;}}
@media only screen and (min-height: 950px) and (max-height: 1000px) {.login-zoom-by-height{zoom: 1;}}

@media only screen and (max-height: 300px)                          {.login-bg{opacity: 0; zoom: 0.35;}}
@media only screen and (min-height: 300px) and (max-height: 350px)  {.login-bg{opacity: 0; zoom: 0.40;}}
@media only screen and (min-height: 350px) and (max-height: 400px)  {.login-bg{opacity: 0; zoom: 0.45;}}
@media only screen and (min-height: 400px) and (max-height: 450px)  {.login-bg{opacity: 0; zoom: 0.50;}}
@media only screen and (min-height: 450px) and (max-height: 500px)  {.login-bg{opacity: 0; zoom: 0.55;}}
@media only screen and (min-height: 500px) and (max-height: 550px)  {.login-bg{opacity: 0; zoom: 0.60;}}
@media only screen and (min-height: 550px) and (max-height: 600px)  {.login-bg{opacity: 0; zoom: 0.65;}}
@media only screen and (min-height: 600px) and (max-height: 650px)  {.login-bg{ zoom: 0.70;}}
@media only screen and (min-height: 650px) and (max-height: 700px)  {.login-bg{ zoom: 0.75;}}
@media only screen and (min-height: 700px) and (max-height: 750px)  {.login-bg{ zoom: 0.80;}}
@media only screen and (min-height: 750px) and (max-height: 800px)  {.login-bg{ zoom: 0.85;}}
@media only screen and (min-height: 800px) and (max-height: 850px)  {.login-bg{ zoom: 0.90;}}
@media only screen and (min-height: 850px) and (max-height: 900px)  {.login-bg{ zoom: 0.95;}}
@media only screen and (min-height: 900px) and (max-height: 950px)  {.login-bg{ zoom: 1;   }}
@media only screen and (min-height: 950px) and (max-height: 1000px) {.login-bg{ zoom: 1;   }}


/*clinic-details-height-responsive*/
/*@media only screen and (min-height: 600px) and (max-height: 650px)  {.clinic-details-height-responsive{zoom: 0.70;}}*/
/*@media only screen and (min-height: 650px) and (max-height: 700px)  {.clinic-details-height-responsive{zoom: 0.75;}}*/
/*@media only screen and (min-height: 700px) and (max-height: 750px)  {.clinic-details-height-responsive{zoom: 0.75;}}*/
/*@media only screen and (min-height: 750px) and (max-height: 800px)  {.clinic-details-height-responsive{zoom: 0.75;}}*/
/*@media only screen and (min-height: 800px) and (max-height: 850px)  {.clinic-details-height-responsive{zoom: 0.80;}}*/
/*@media only screen and (min-height: 850px) and (max-height: 900px)  {.clinic-details-height-responsive{zoom: 0.88;}}*/
/*@media only screen and (min-height: 900px) and (max-height: 950px)  {.clinic-details-height-responsive{zoom: 1;}}*/
/*@media only screen and (min-height: 950px) and (max-height: 1000px) {.clinic-details-height-responsive{zoom: 1;}}*/
