.chart-legend{
    text-align: -webkit-auto;
    margin: 3%;

}

.big{
    background-color: red;
}

.recolor-button{
    background-color: gray;
    opacity: 0.5;
}