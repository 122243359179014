.g-modal-overlay{
    height:100%;
    overflow:auto;
}

@media only screen and (max-height: 300px)                          {.g-modal-body{zoom: 0.35;}}
@media only screen and (min-height: 300px) and (max-height: 350px)  {.g-modal-body{zoom: 0.40;}}
@media only screen and (min-height: 350px) and (max-height: 400px)  {.g-modal-body{zoom: 0.45;}}
@media only screen and (min-height: 400px) and (max-height: 450px)  {.g-modal-body{zoom: 0.50;}}
@media only screen and (min-height: 450px) and (max-height: 500px)  {.g-modal-body{zoom: 0.55;}}
@media only screen and (min-height: 500px) and (max-height: 550px)  {.g-modal-body{zoom: 0.60;}}
@media only screen and (min-height: 550px) and (max-height: 600px)  {.g-modal-body{zoom: 0.65;}}
@media only screen and (min-height: 600px) and (max-height: 650px)  {.g-modal-body{zoom: 0.70;}}
@media only screen and (min-height: 650px) and (max-height: 700px)  {.g-modal-body{zoom: 0.75;}}
@media only screen and (min-height: 700px) and (max-height: 750px)  {.g-modal-body{zoom: 0.80;}}
@media only screen and (min-height: 750px) and (max-height: 800px)  {.g-modal-body{zoom: 0.85;}}
@media only screen and (min-height: 800px) and (max-height: 850px)  {.g-modal-body{zoom: 0.90;}}
@media only screen and (min-height: 850px) and (max-height: 900px)  {.g-modal-body{zoom: 0.95;}}
@media only screen and (min-height: 900px) and (max-height: 950px)  {.g-modal-body{zoom: 1;}}
@media only screen and (min-height: 950px) and (max-height: 1000px) {.g-modal-body{zoom: 1;}}
