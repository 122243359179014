.LoaderButton{
  /* margin-top: 118px; */
  height: 6vh;
  width: 15vw;
  border: 0px;
  font-family: Gotham HTF Book;
  font-style: normal;
  font-weight: 900;
  font-size: 2vh;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #3AB5AC;
  border-radius: 16vh;
}
.LoaderButton:disabled,.LoaderButton:hover{
  border: 0px;

  background: #3AB5AC;

}
.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;

}

@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

